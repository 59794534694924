<template>
    <div>
        <h4>Referenser Page</h4>
        <b-container>
            <b-row>
                <b-col md="6">
                    <b-card v-for="image in galleryImageList" v-bind:key="image.id">
                        <b-container>
                            <b-row>
                                <b-col cols="6">
                                    <a :href="image.url" target="_blank"><img :src="image.url" class="img-fluid thumbnail" :alt="image.title"></a>
                                </b-col>
                                <b-col cols="6">
                                    <p>
                                        <b>{{image.title}}</b><br>
                                        {{image.description}}
                                    </p>
                                </b-col>
                                <b-col cols="12" class="mt-1">
                                    <a href="#" v-if="galleryImageList.length > 1"  class="delete" @click.prevent="deleteGallery(image.id)">Delete</a>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card>
                        <b>Upload new image</b><br>
                        <b-form-group id="title-group">
                            <b-form-input
                                    id="title-input"
                                    placeholder="Title"
                                    v-model="galleryTitle"
                                    required
                            />
                        </b-form-group>
                        <b-form-group id="description-group">
                            <b-form-input
                                    id="description-input"
                                    placeholder="Description"
                                    v-model="galleryCaption"
                                    required
                            />
                        </b-form-group>
                        <b-form-group id="gallery-file-group">
                            <b-form-file v-model="fileGallery" type="file" accept="image/png, image/jpeg, image/gif" id="fileGallery" ref="fileGallery" required/>
                        </b-form-group>
                        <b-button class="default-button" v-on:click="submitFileGallery()">Submit</b-button>
                        <img v-show="uploading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import config from 'config';
    import axios from "axios";

    export default {
        name: "GalleryPage",
        data () {
            return {
                galleryImageList: [],
                fileGallery: '',
                galleryTitle: '',
                galleryCaption: '',
                uploading: false,
            }
        },
        mounted() {
            this.getGalleryImages();
        },
        methods: {
            getGalleryImages() {
                this.galleryImageList = [];
                axios.get(`${config.apiUrl}/carousel/gallery`)
                    .then(response => {
                        response.data.forEach(e => {
                            this.galleryImageList.push({
                                'id': e.id,
                                'url': `${config.apiUrl}${e.img_path}`,
                                'title': e.img_title,
                                'description': e.img_caption
                            });
                        })
                    });
            },

            deleteGallery(imageId) {
                if (confirm('Are you sure you want to delete this image?')) {
                    axios.delete( `${config.apiUrl}/carousel/gallery`,  {
                        data: `id=${imageId}`,
                        withCredentials: false,
                        headers: {
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    },).then(() => this.getGalleryImages())
                        .catch((e) => {
                            if (e.response) {
                                console.log(e.response.status)
                                if (e.response.status === 403) {
                                    this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                                    this.$router.push('/login');
                                } else if (e.response.status === 502) {
                                    this.$store.dispatch('alert/error', 'Server error.', {root: true});
                                } else {
                                    this.$store.dispatch('alert/error', e.response, { root: true });
                                }
                            }
                        });
                }

            },

            submitFileGallery() {
                if (this.fileGallery === '') {
                    alert("Please select a picture.");
                    return;
                }

                if (this.galleryTitle === '') {
                    alert("Please insert image title.");
                    return;
                }

                if (this.galleryCaption === '') {
                    alert("Please insert image description.");
                    return;
                }
                this.uploading = true;

                let formData = new FormData();
                formData.append('img_file', this.fileGallery);
                formData.append('img_title', this.galleryTitle);
                formData.append('img_caption', this.galleryCaption);

                axios.post( `${config.apiUrl}/carousel/gallery`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        }
                    }
                ).then(() => {
                    this.getGalleryImages();
                    this.clearGalleryFileInput();
                    this.galleryTitle = '';
                    this.galleryCaption = '';
                    this.uploading = false;
                }).catch((e) => {
                    this.uploading = false;
                    if (e.response) {
                        console.log(e.response.status)
                        if (e.response.status === 403) {
                            this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                            this.$router.push('/login');
                        } else if (e.response.status === 502) {
                            this.$store.dispatch('alert/error', 'Server error.', {root: true});
                        } else if (e.response.data.message) {
                            this.$store.dispatch('alert/error', e.response.data.message, { root: true });
                        } else {
                            this.$store.dispatch('alert/error', e.response.data, { root: true });
                        }
                    }
                });
            },

            clearGalleryFileInput () {
                const input = this.$refs.fileGallery;
                input.type = 'text';
                input.type = 'file';
            },
        }
    }
</script>

<style scoped>
    .thumbnail {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .delete {
        color: red;
    }
</style>
